<template>
  <v-main>
  <div class="main-content main-content_fw">
    <Card class="sticky-filters card-custom">
    <template #content>
        <v-row>
          <v-col class="filters-column">
            <!-- <Calendar :placeholder="$t('date')" :touchUI="$isMobile() ? true : false" :maxDate="today" id="icon" dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" :showIcon="true" /> -->
            <!-- <Dropdown 
            class="custom-dropdown"
            v-if="!isAgent"
            v-model="filters.agent_id" 
            :options="agents.items"
            optionValue="id" optionLabel="login"
            :placeholder="$t('agents')" :filter="true" :emptyFilterMessage="$t('no_data')">
            <template #option="slotProps">
              {{ slotProps.option.login }} ({{$t('level')}}: {{ slotProps.option.level }})
            </template>
          </Dropdown> -->
          <paggination-listbox
            v-if="!isAgent"
            v-model="filters.agent_id"
            :options="agents"
            @start-loading="startAgentsLoading"
            :maxWidthMenu="600"
            :loading="loadingAgentsFlag"
            :loadingStart="startAgentsLoadingFlag"
            @load-next-page="loadAgentsNextPage"
            :placeholder="$t('agents')"
            optionValue="id" optionLabel="login"
            ></paggination-listbox>
          <InputText 
          v-else
                            disabled
                            :value="admin.login"
                            type="text"
                             class="p-inputtext-sm" 
                             :placeholder="$t('agents')">
                            </InputText>
                            <InputText 
      disabled
      :value="getCurrencyValue"
      type="text"
       class="p-inputtext-sm" 
       :placeholder="$t('currency')" />
                            <!-- <div>
                              <div class="p-inputgroup">
                                <Button
                                v-if="filters.user_id"
                                @click.stop="filters.user_id = null"
                                icon="pi pi-times" class="p-button-danger"/>
                                <InputText 
                              readonly
                              :value="filters.user_id ? filters.user_id : ''"
                              type="text"
                              @click="dialogVisible = true"
                               class="p-inputtext-sm" 
                               :placeholder="$t('player')">
                              </InputText>
                              </div>
                            </div> -->
                           
          
          <Dropdown v-model="filters.group_by" :options="timePeriods"
          :placeholder="$t('time_period')" optionValue="value" optionLabel="text" :emptyFilterMessage="$t('no_data')">
        </Dropdown>
        <Dropdown v-model="filters.result" :options="results"
        :placeholder="$t('format')" optionValue="value" optionLabel="value" :emptyFilterMessage="$t('no_data')">
      </Dropdown>
      
      <date-range-picker 
      :autoApply="true"
      v-model="dateRange"
      id="datepicker_id" :showDropdowns="true" 
      ref="picker" 
      opens="center"
      :max-date="today.toISOString()"
      format="yyyy/mm/dd" 
      :locale-data="locale_data"
      >
                <div slot="input" slot-scope="picker">{{ dateRange.startDate | date }} - {{ 
                  dateRange.endDate | date }}
                </div>
              </date-range-picker>
              
                <span class="p-buttonset">
                  <Button @click="deleteFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('delete')" icon="pi pi-trash" class="p-button-sm p-button-danger" />
                  <Button @click="generateReport" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('generate_report')" icon="pi pi-file-import" class="p-button-sm p-button-success" />
              </span>
              
          </v-col>
          </v-row>
            <!-- <v-chip-group>
              <v-chip class="active-filter-chip" v-for="(value, key) in filteredActiveFilters"  :key="key" @click="removeFilter(key)">
                <template>
                {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key,value) }}
                </template>
                <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
              </v-chip>
            </v-chip-group> -->
    </template>
    </Card>
    <Card class="mt-2 card-custom" v-if="showReport">
      <template #content>
        <div class="report">
          <div class="report__header header-report">
              <h3 class="text-uppercase">
                {{ $t('agents_financial_report') }}
              </h3>
              <Divider />
              <h6 class="mt-1">
               from {{ getFormattedDates(filters.from_date) }} to {{ getFormattedDates(filters.end_date) }} by {{ getFormattedPeriod(currentGroupBy) }}
              </h6>
          </div>
          <DataTable :value="totals" :rows="totals.length" :loading="loadingFlag"
               :scrollable="true"
               
               responsiveLayout="scroll"
               class="p-datatable-sm mb-1">
               <Column field="name" 
               :showFilterMenu="false" 
               :header="$t('totals')"
               bodyClass="bg-c-white"
               headerClass="bg-c-white text-uppercase"
               :styles="{ 'min-width': '12rem' }"
              >
                <template #body="{data}" :styles="{'min-width': '10rem'}">
                  <v-col class="pa-0 d-flex flex-wrap"><span class="font-weight-bold">{{ $t(data.name) }}</span></v-col>
                </template>
              </Column>
              <Column headerClass="justify-center bg-c-white" bodyClass="justify-end bg-c-white" 
              field="value" 
              :styles="{ 'min-width': '10rem' }">
                <template #body="{ data }">
                  <div class="d-flex align-center font-weight-bold" style="white-space: nowrap;">{{ data.value }} {{ filters.currency }} </div>
                </template>
              </Column>
            </DataTable>
          <div class="report__table table-report space-y-1">
            <Card v-for="(date, index) in reports.dateColumns" :key="index" class="table-wrapper card-custom">
              <template #title>
                <h3 class="text-left">{{ date }}</h3>
                </template>
              <template #content>
              <DataTable :value="reports.items[date]" 
              :scrollable="true"
              :rows="reports.items[date].length" class="p-datatable-sm">
                
                <!-- Первая колонка - агент -->
                <Column field="name" header="Agent" :styles="{ 'width': '6rem' }">
                  <template #body="{ data }">
                    <span>{{ data.name }}</span>
                  </template>
                </Column>
                <Column v-for="(param, index) in parameterKeys(date)" 
                headerClass="justify-end text-right" bodyClass="justify-end"
                :key="index" :field="param" :header="$t(param)" :styles="{ 'min-width': '9rem' }">
                  <template #body="{ data }">
                    <span>{{ getParamValue(data, param) }}</span>
                  </template>
                </Column>
                
              </DataTable>
            </template>
            </Card>
          
            </div>
          </div>
          
      </template>
    </Card>
    <Dialog :header="$t('select_player_and_confirm')" :visible.sync="dialogVisible" :style="{width: '95vw'}" :maximizable="true" :modal="true" :contentStyle="{height: '500px'}">
     <players-data-table 
     @selected-player="toSelectPlayer"
     />
      <template #footer>
            <Button :label="$t('confirm')" icon="pi pi-check"
            class="p-button-sm p-button-success fw"
            @click="toSelectUser" />
      </template>
  </Dialog>
  </div>
  </v-main>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { mapGetters } from 'vuex';
import PlayersDataTable from '../Players/PlayersDataTable';
  export default {
    components:{
      DateRangePicker,
      PlayersDataTable
    },
      name: 'AgentsFinancialReport',
      data() {
          return {
              currentLocale: this.$i18n.locale || 'en',
              loadingAgentsFlag: false,
              startAgentsLoadingFlag: false,
              today: new Date(),
              loadingFlag: false,
              showReport: false,
              activeFilters:{},
              selectedPlayer: null,
              datesToFilter: [],
              generatedCurrency: '',
              dialogVisible: false,
              dateRange:{},
              currentGroupBy: 'D',
              filters:{
                agent_id: '',
                user_id: 	'',
                currency: 'USD',
                group_by: 'D',
                result: 'json',
              },
              results: [
              {
                value: 'json'
              },
              {
                value: 'pdf'
              },
              ],
              locale_data: {
      direction: 'ltr',
      format: 'mm.dd.yyyy',
      separator: ' - ',
      applyLabel: this.$i18n.t('applyLabel'),
      cancelLabel: this.$i18n.t('cancelLabel'),
      weekLabel: this.$i18n.t('weekLabel'),
      customRangeLabel: this.$i18n.t('customRangeLabel'),
      daysOfWeek: [
        this.$i18n.t('sun'), 
      this.$i18n.t('mon'),
       this.$i18n.t('tue'), 
       this.$i18n.t('wed'), 
       this.$i18n.t('thu'), 
       this.$i18n.t('fri'), 
       this.$i18n.t('sat')],
      monthNames: [this.$i18n.t('jan'), this.$i18n.t('feb'), this.$i18n.t('mar'), this.$i18n.t('apr'), this.$i18n.t('may'), this.$i18n.t('jun'), this.$i18n.t('jul'), this.$i18n.t('aug'), this.$i18n.t('sep'), this.$i18n.t('oct'), this.$i18n.t('nov'), this.$i18n.t('dec')],
      firstDay: 0,
    },
              timePeriods:[
                {
                text: this.$t('day'), value: 'D'
              },
                {
                text: this.$t('week'), value: 'W'
              },
                {
                text: this.$t('month'), value: 'M'
              },
                {
                text: this.$t('year'), value: 'Y'
              }
            ],
          }
      },
      filters: {
  date(val) {
   return val ? moment(val).format("YYYY-MM-DD") : "";
 }
},
      computed:{
          ...mapGetters({
              reports: 'reports/getAgentsFinancialReport',
              totals: 'reports/getAgentsFinancialReportTotals',
              currencies: 'getFilteredCurrencies',
              agents: 'agents/getAgents',
              tokenUser: 'tokenUser',
              admin: 'admin',
          }),
          getCurrencyValue(){
          if (this.isAgent) {
            return this.admin.currency;
          }  else{
            if (!this.getAgentID) {
              return 'USD';
            }
            const parentAgent = this.agents.items.find(agent => agent.id === this.getAgentID);
            if (parentAgent) {
              return parentAgent.currency;
            } else{ 
              return 'USD';
            }
          }
          },
          getAgentID(){
            if (!this.agents.items && this.agents.items.length === 0) {
              return null;
            }
              if (this.isAgent) {
                return this.admin.id;
            } else {
              const parentAgent = this.agents.items.find(agent => agent.id === this.filters.agent_id);
              // console.log(parentAgent);
              if (parentAgent) {
                return parentAgent.id;
              } else{
                      return null;
              }
            }
          },
          isAgent(){
            return this.tokenUser === "agent";
          },
      },
      beforeRouteEnter(to, from, next){
  next(vm => {
    vm.$store.commit('setPageName', 'agents_financial_report')
  });
  },
      async mounted(){
        this.loadingFlag = true;
        this.dateRange = { startDate: moment().subtract(6, 'days'), endDate: moment() }
        if (!this.agents.items.length && !this.isAgent) {
          await this.startAgentsLoading();
        }
        if (!this.isAgent) {
          this.filters.agent_id = this.agents.items[0].id;
        }
        if (this.isAgent) {
            this.filters.agent_id = this.admin.id;
          }
        this.loadingFlag = false;
      },
      methods:{
        async startAgentsLoading(){
          if (this.agents.items.length) {
            return
          }
          this.startAgentsLoadingFlag = true;
          const filters = {
            size: 100,
            page: 1
          }
          try {
            await this.$store.dispatch("agents/awaitGetAgents", filters);
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
          } finally{
            this.startAgentsLoadingFlag = false;
          }
        },
        async loadAgentsNextPage(){
          this.loadingAgentsFlag = true;
          const filters = {
            size: 100,
            page: this.agents.page + 1
          }
          try {
            await this.$store.dispatch("agents/awaitGetAgents", filters);
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), detail: error.message, life: 4000 })
          } finally{
            this.loadingAgentsFlag = false;
          }
        },
        parameterKeys(date) {
      const firstAgentData = this.reports.items[date];
      if (firstAgentData && firstAgentData.length > 0) {
        // Получаем ключи параметров из первого объекта values
        return Object.keys(firstAgentData[0]).filter(key => key !== 'name');
      }
      return []; 
    },
    getParamValue(agent, param) {
      // console.log(agent, param);
      return agent[param] !== undefined ? agent[param] : '-';
    },
        toSelectPlayer(player){
          this.selectedPlayer = player;
        },
        toSelectUser(){
          this.dialogVisible = false;
          if (this.selectedPlayer) {
            this.filters.user_id = this.selectedPlayer
          }
        },
        getFormattedDates(date){
          return moment(date).format('YYYY-MM-DD')
        },
        getFormattedPeriod(period){
          switch (period) {
            case 'D':
            return this.$t('day');
            case 'W':
                return this.$t('week');
                case 'M': 
                return this.$t('month');
                case 'Y':
                return this.$t('year');
          }
        },
        customClass(field){
           return [
                 {
                    //  'total': field.name.includes('total_'),
                     'title': field.values.length === 0,
                 },
             ];
        },
        getValuesForDate(data, date) {
    return data.values.filter(value => value.created_at === date).map(value => value.value);
  },
  //       getValueForDate(data, date) {
  //   const item = data.values.find(value => value.created_at === date);
  //   return item ? item.value : '';
  // },
  //       getValueForDate(data, date) {
  //         const values = data.values;
  //         if (values.length === 0) {
  //           return '';
  //         }
  //         const matchingItem = values.find(item => item.created_at === date);
  //         return matchingItem && matchingItem.value ? matchingItem.value : 0;
  // },
          async generateReport(){
              this.loadingFlag = true;
              this.filters.currency = this.getCurrencyValue;
              this.filters.agent_id = this.getAgentID;
              this.filters.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
              this.filters.end_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
              this.filters.tz_offset = moment(this.dateRange.startDate).format('Z')
              this.currentGroupBy = this.filters.group_by;
              try {
                await this.$store.dispatch('reports/awaitGetAgentsFinancialReport', this.filters);
                this.generatedCurrency = this.filters.currency;
                if (this.filters.result !== 'pdf') {
                  this.showReport = true;
                }
                this.$toast.add({severity: 'success', summary: this.$t('success_report'), life: 3000}) 
               } catch (error) {
                 this.$toast.add({severity: 'error', summary: this.$t('just_error'), life: 3000}) 
               } finally{

                 this.loadingFlag = false;
               }
          },
          deleteFilters(){
              this.loadingFlag = true;
              this.showReport = false;
              if (!this.isAgent) {
                this.filters.agent_id = '';
              }
              this.datesToFilter = [];
              this.$nextTick(() =>{
                this.loadingFlag = false;
              })
          },
      },
  }
</script>

<style lang="scss" scoped>
.report{
  overflow: auto;
}

.input-menu{
  color: rgba(0,0,0,.87);
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  position: relative;
  &__menu{
    background-color: var(--white);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    //top: 0;
    //left: 0;
    //position: absolute;
    .v-input{
      margin-top: 8px;
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
.filtersCard {
  width: 100%;
  z-index: 3;
  position: -webkit-sticky;
  position: sticky;
  top: 63px;
  @media screen and (max-width: 960px), (max-height: 500px) and (orientation: landscape) {
        position: static !important;
    }
}
</style>