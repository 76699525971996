<template>
      <Card class="sticky-filters card-custom" style="position: relative;">
        <template #title>
          <Toolbar>
            <template #start>
              <div class="d-flex justify-center">
                <h3>{{ $i18n.t('search') }}
                  {{ $i18n.t('found') }}: {{ players.total ? players.total : 0 }}</h3>
              </div>
            </template>
            <template #end>
                <div class="filters-column">
                  <v-menu v-model="moreMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    nudge-left="0" nudge-right="0">
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="p-float-label p-input-icon-right">
                        <i class="pi pi-filter" />
                        <InputText type="text" readonly class="p-inputtext-sm" :placeholder="$t('more')">
                        </InputText>
                      </span>
                    </template>
                    <div class="input-menu__menu text-left" v-on:keyup.enter="applyFilters">
                      <InputText type="text" @input="debounceFilters" v-model="filters.username" class="p-inputtext-sm"
                        :placeholder="$t('username')" />
                      <InputText type="text" @input="debounceFilters" v-model="filters.name" class="p-inputtext-sm" :placeholder="$t('name')" />
                      <InputText type="text" @input="debounceFilters" v-model="filters.surname" class="p-inputtext-sm" :placeholder="$t('surname')" />
                      <InputText type="text" @input="debounceFilters" v-model="filters.email" class="p-inputtext-sm" :placeholder="$t('email')" />
                      <InputText type="text" @input="debounceFilters" v-model="filters.tel" class="p-inputtext-sm" :placeholder="$t('phone')" />
                      <InputText type="text" @input="debounceFilters" v-model="filters.affiliate" class="p-inputtext-sm"
                        :placeholder="$t('affiliate')" />
                    </div>
        
                  </v-menu>
                  <span class="p-buttonset">
                    <Button @click="clearFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('clear')"
                      icon="pi pi-trash" class="p-button-sm p-button-danger" />
                      <Button @click="applyFilters" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('search')"
                      icon="pi pi-search" class="p-button-sm p-button-success" />
                    </span>
                </div>
                
              </template>
        </Toolbar>
          
              <v-chip-group>
                <Chip class="active-filter-chip mr-1" v-for="(value, key) in filteredActiveFilters" 
                :key="'filter' + key"
                  >
                  <template>
                    {{ getFilterValueTitle(key) }}: {{ getFilterValueText(key, value) }}
                  </template>
                  <v-icon @click.stop="removeFilter(key, value)">mdi-close</v-icon>
                </Chip>
              </v-chip-group>
        </template>
        <template #content>
          <DataTable 
          :value="players.items"
          :rows="players.size" 
          :loading="loadingFlag" 
          responsiveLayout="scroll"
            :selection.sync="selectedPlayer" 
            selectionMode="single"
            filterDisplay="row" 
            :scrollable="true" 
            scrollHeight="flex"
            stripedRows
            removableSort
            class="p-datatable-sm">
            <template #empty>
              {{ $t('no_data') }}
            </template>
            <Column selectionMode="single" :header="$t('select')" :headerStyle="{'min-width': '2em'}"></Column>
          <!-- bodyClass="justify-end" -->
            <Column  field="is_online" :showFilterMenu="false" :styles="{ 'min-width': '5rem', 'padding-left' : '8px' }">
              <template #header>
                {{ $t('online') }}  
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.right="$t('online_hint')"></Badge> -->
              </template>
              <template #body="{data}">
                  <div class="online-circle" :class="{ 'is-online' : data.is_online }" >
    
                  </div>
              </template>
              <template #filter>
                <div class="field-checkbox d-flex align-center">
                  <Checkbox id="binary" @change="applyFilters" v-model="filters.is_online" :binary="true" />
                  <!-- <label for="binary" class="ml-1">{{ $t('online') }}</label> -->
                </div>
              </template>
            </Column>
            <Column sortable field="country_code" :showFilterMenu="false" :styles="{ 'min-width': '9rem' }">
              <template #header>
                {{$t('country')}} 
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('country_hint')"></Badge> -->
              </template>
              <template #body="{data}">
                  {{ data.country_code  }}
              </template>
              <template #filter>
                <InputText type="text"
                @input="debounceFilters"
                 v-model="filters.country_code" class="p-inputtext-sm"
                  :placeholder="$t('country')" />
              </template>
            </Column>
            <Column sortable field="id" :showFilterMenu="false" :styles="{ 'min-width': '9rem' }">
              <template #header>
                ID 
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('id_hint')"></Badge> -->
              </template>
              <template #body="{data}">
                <router-link class="c-main-flamingo" :to="`player/${data.id}`">
                  {{ data.id }}
                </router-link>
                <span class="ml-1"> ({{ data.username }})</span>
              </template>
              <template #filter>
                <InputText type="text"
                @input="debounceFilters"
                 v-model="filters.id[0]" class="p-inputtext-sm"
                  placeholder="ID" />
              </template>
            </Column>
            <Column sortable field="total_deposit_amount" :showFilterMenu="false"
            :styles="{ 'min-width': '10rem' }">
            <template #header>
              {{ $t('deposits') }}  
              <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('deposits_hint')"></Badge> -->
            </template>
              <template #body="{ data }">
                <div class="d-flex align-center">
                  <div class="mr-1">
                    <div v-if="data.total_deposit_amount > 0"
                      style="width: 20px;height: 20px;background: var(--green-500);border-radius: 3px;"></div>
                    <div v-else style="width: 20px;height: 20px;background: var(--black);border-radius: 3px;opacity: .2">
                    </div>
                  </div>
                  <div class="d-flex align-center">
      
                    <span>
                      {{ data.total_deposit_amount.toLocaleString() }}
                    </span>
                    <span class="ml-1">
                      {{ data.currency }}
                    </span>
                  </div>
                </div>
              </template>
              <template #filter>
                <div class="field-checkbox">
                  <Checkbox id="binary" @change="applyFilters" v-model="filters.has_deposit" :binary="true" />
                  <!-- <label for="binary" class="ml-1">{{ $t('with_deposits') }}</label> -->
                </div>
              </template>
            </Column>
            <Column sortable field="balance" :showFilterMenu="false" :styles="{ 'min-width': '10rem' }">
              <template #header>
                {{ $t('balance') }}  
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('balance_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div class="d-flex align-center">
                  <span>{{ data.balance.toLocaleString() }} </span>
                  <span class="ml-1">{{ data.currency }}</span>
                </div>
              </template>
              <template #filter>
                <v-menu v-model="balanceMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                  nudge-left="0" nudge-right="0">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="p-float-label p-input-icon-right">
                      <i class="pi pi-money-bill" />
                      <InputText type="text" readonly class="p-inputtext-sm pr-8" :placeholder="
                      getBalancePlaceholder
                      ">
                      </InputText>
                    </span>
                  </template>
                  <div class="input-menu__menu">
                    <InputText type="text" @input="debounceFilters" v-model="filters.min_balance" class="p-inputtext-sm"
                      :placeholder="$t('balance_from')" />
                    <InputText type="text" @input="debounceFilters" v-model="filters.max_balance" class="p-inputtext-sm"
                      :placeholder="$t('balance_to')" />
                  </div>
      
                </v-menu>
              </template>
            </Column>
            <Column sortable field="total_withdraw_amounts" :showFilterMenu="false"
              :styles="{ 'min-width': '12rem' }">
              <template #header>
                {{ $t('withdrawals') }}  
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('withdrawals_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div class="d-flex align-center">
                  <span>{{ data.total_withdraw_amounts.toLocaleString() }} </span>
                  <span class="ml-1">{{ data.currency }}</span>
                </div>
              </template>
              <template #filter>
                <v-menu v-model="withdrawalsMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                  nudge-left="0" nudge-right="0">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" class="p-float-label p-input-icon-right">
                      <i class="pi pi-money-bill" />
                      <InputText type="text" readonly class="p-inputtext-sm pr-8"
                        :placeholder="getWithdrawPlaceholder">
                      </InputText>
                    </span>
                  </template>
                  <div class="input-menu__menu">
                    <InputText type="text" @input="debounceFilters" v-model="filters.min_withdrawal"
                      class="p-inputtext-sm" :placeholder="$t('amount_from')" />
                    <InputText type="text" @input="debounceFilters" v-model="filters.max_withdrawal"
                      class="p-inputtext-sm" :placeholder="$t('amount_to')" />
                  </div>
      
                </v-menu>
              </template>
            </Column>
            <Column sortable field="created_at" :showFilterMenu="false" :styles="{ 'min-width': '12rem' }">
              <template #header>
                {{ $t('registered') }}  
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('registered_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div style="white-space: nowrap">
                  {{ new Date(data.created_at).toLocaleString() }}
                </div>
              </template>
              <template #filter>
                  <v-menu 
                  v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
                    <template v-slot:activator="{ on }">
        
                      <InputText v-on="on" type="text" readonly class="p-inputtext-sm" :placeholder="$t('date')"
                        v-model="getFilteredDates">
                      </InputText>
        
                    </template>
                    <Calendar class="calendar-menu" :placeholder="$t('date')" :inline="true" :maxDate="today" id="icon"
                      dateFormat="mm.dd.yy" v-model="datesToFilter" selectionMode="range" />
                  </v-menu>
              </template>
            </Column>
            <Column sortable field="block" :showFilterMenu="false" :styles="{ 'min-width': '12rem' }">
              <template #header>
                {{ $t('account_status') }}  
                <!-- <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('account_status_hint')"></Badge> -->
              </template>
              <template #body="{ data }">
                <div class="d-flex align-center">
                  <span class="custom-badge"
                :class="getAccountStatusClass(data.block, data.block_end)"
                >
                  <span>{{ getAccountStatus(data.block, data.block_end) }} </span>
                  <span v-if="!data.block && data.block_end">({{ formatDate(data.block_end) }})</span>
                </span>
                </div>
              </template>
              <template #filter>
                <!-- <Dropdown @keydown.enter="applyFilters" v-model="selectedOption" @change="handleChange($event)"
                optionLabel="text" :placeholder="$t('method')" :options="options" :emptyFilterMessage="$t('no_data')">
              </Dropdown> -->
              <!-- class="under-header" -->
              <div id="select-option"
              
              >
              <!-- attach="#select-option" -->
                <v-menu v-model="optionMenu" 
                offset-y
                :close-on-content-click="false" transition="scale-transition"
                    nudge-left="0" nudge-right="0">
                    <template v-slot:activator="{ on }">
                     
                        <span class="p-input-icon-right" v-on="on" >
                          <i class="pi pi-angle-down" />
                          <InputText 
                          :value="selectedOption && selectedOption.text ? selectedOption.text : ''"
                          type="text" readonly class="p-inputtext-sm" :placeholder="$t('method')">
                          </InputText>
                        </span>
                    </template>
                    <!-- <div class="input-menu__menu">
                    </div> -->
                      <Listbox v-model="selectedOption" :options="options"
                      @change="handleChange($event)"
                      :emptyFilterMessage="$t('no_data')"
                      optionLabel="text" style="width:15rem" />
        
                  </v-menu>
              </div>
            </template>
            </Column>
          </DataTable>
          <games-paggination 
          :pagginationObject="filters" 
          :requestData="players" 
          @apply-filters="setAndSubmitFilters" 
          :isStatic="true"
          />
        </template>
      </Card>
     
  </template>
  
  
  <script>
  import { debounce } from 'lodash'
  import { mapGetters } from "vuex";
  import GamesPaggination from '@/views/GamesPaggination'
  export default {
    name: 'PlayersDataTable',
    components: { 
      GamesPaggination,
    },
    props: {
      agentID: Number,
    },
    computed: {
      ...mapGetters({
        currency: 'currency',
        players: 'getPlayers',
        gameFilters: 'paggination/getFilters',
      },
      ),
      getBalancePlaceholder() {
        const { min_balance, max_balance } = this.filters;
        if (min_balance && max_balance) {
          
        
        const trimMinBalance = min_balance.trim();
        const trimMaxBalance = max_balance.trim();
          if (!trimMinBalance && !trimMaxBalance) {
            return this.$t('amount');
          } else if (!trimMinBalance) {
            return this.$t('to') + ' ' + trimMaxBalance;
          } else if (!trimMaxBalance) {
            return this.$t('from') + ' ' + trimMinBalance;
          } else if(trimMinBalance && trimMaxBalance) {
            return `${this.$t('from')} ${trimMinBalance} ${this.$t('to')} ${trimMaxBalance}`
          } else {
            return this.$t('amount');
          }
        } else{
          return this.$t('amount')
        }
      },
      getWithdrawPlaceholder() {
        const { min_withdrawal, max_withdrawal } = this.filters;
        if (min_withdrawal && max_withdrawal) {
        const trimMinBalance = min_withdrawal.trim();
        const trimMaxBalance = max_withdrawal.trim();
          if (!trimMinBalance && !trimMaxBalance) {
            return this.$t('amount');
          } else if (!trimMinBalance) {
            return this.$t('to') + ' ' + trimMaxBalance;
          } else if (!trimMaxBalance) {
            return this.$t('from') + ' ' + trimMinBalance;
          } else if(trimMinBalance && trimMaxBalance) {
            return `${this.$t('from')} ${trimMinBalance} ${this.$t('to')} ${trimMaxBalance}`
          } else {
            return this.$t('amount');
          }
        } else{
          return this.$t('amount')
        }
      },
      getFilteredDates(){
        if (this.datesToFilter.length > 0) {
          let one;
          let two;
            const formattedDates = this.datesToFilter.map(dateStr => {
              const date = new Date(dateStr);
              return date.toISOString().split('T')[0];
            });
            const formattedDateOne = formattedDates[0];
            const formattedDateTwo = formattedDates[1];
            if (this.datesToFilter.length === 2 && this.datesToFilter[1]) {
              // both data
              one = formattedDateOne;
              two = formattedDateTwo;
              return `${one} - ${two}`
            } else {
              // only one
              one = formattedDateOne;
              return `${one} - ${one}`
            }
          } else{
            return ''
          }
      },
      filteredActiveFilters() {
        const activeFilters = Object.entries(this.activeFilters).filter(
      ([key, value]) => key !== 'size' && key !== 'page' && key !== 'agent_id'
      && (key === 'is_active' || value !== false) 
      && value !== [] 
    );
  
    const activeFiltersObject = Object.fromEntries(activeFilters);
  
    if (Array.isArray(activeFiltersObject.id)) {
    activeFiltersObject.id.forEach((id, index) => {
      activeFiltersObject[`ID_${index + 1}`] = id;
    });
    delete activeFiltersObject.id; 
  }
  
    return activeFiltersObject;
      },
    },
    data() {
      return {
        selectedPlayer: null,
        toAddDialog: false,
        loadingFlag: false,
        currentLocale: this.$i18n.locale || 'en',
        loadingModalFlag: false,
        optionMenu: false,
        dateMenu: false,
        balanceMenu: false,
        withdrawalsMenu: false,
        moreMenu: false,
        today: new Date(),
        datesToFilter: [],
        options: [
          { value: 'active', text: this.$i18n.t('active') },
          { value: 'forever_blocked', text: this.$i18n.t('forever_blocked') },
          { value: 'temp_blocked', text: this.$i18n.t('temp_blocked') },
          { value: 'blocked_withdraw', text: this.$i18n.t('withdraw_blocked') },
        ],
        selectedOption: null,
        filters: {
          affiliate_id: '',
          is_active: '',
          // is_online: false,
          // pending_verification: false,
          // has_multiaccs: false,
          // has_deposit: false,
          min_balance: '',
          max_balance: '',
          min_withdrawal: '',
          max_withdrawal: '',
          min_reg_date: '',
          max_reg_date: '',
          lvl_name: '',
          id: [],
          username: '',
          surname: '',
          email: '',
          name: '',
          comment: '',
          tel: '',
          country_code: '',
          temp_blocked: '',
          blocked_withdraw: '',
        },
        startPage: 1,
        page: 1,
        size: 25,
        pageOptions: [10, 25, 100],
        filterPages: 25,
        activeFilters: {},
      }
    },
    watch: {
      datesToFilter(newValue, oldValue){
              if (newValue[0] && newValue[1]) {
                this.applyFilters()
              }
            },
      selectedPlayer(newValue, oldValue) {
        if (newValue) {
          this.$emit('selected-player', newValue.id)
        }
      }    

    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$store.commit('setPageName', 'players_list')
      });
    },
    async mounted() {
        if (this.players.items.length === 0) {
            await this.applyFilters();
        }
    },
    methods: {
      debounceFilters: debounce(async function() {
        await this.applyFilters();
      }, 750),
      async handleChange(event) {
        if (!event.value) {
          this.filters.blocked_withdraw = false;
          this.filters.temp_blocked = false;
          this.filters.is_active = '';
          this.optionMenu = false;
          await this.applyFilters();
          return
        }
        if (event.value.value === 'active') {
          this.filters.is_active = true;
          this.filters.temp_blocked = false;
          this.filters.blocked_withdraw = false;
          console.log(this.filters.is_active);
        } else if (event.value.value === 'forever_blocked') {
          this.filters.is_active = false;
        } else if (event.value.value === 'temp_blocked') {
          this.filters.temp_blocked = true;
          this.filters.blocked_withdraw = false;
          this.filters.is_active = '';
        } else if (event.value.value === 'blocked_withdraw') {
          this.filters.blocked_withdraw = true;
          this.filters.temp_blocked = false;
          this.filters.is_active = '';
        }
        this.optionMenu = false;
        await this.applyFilters();
      },
      getFilterValueTitle(key) {
        // chip keys
        switch (key) {
          case 'is_active':
            return this.$t('status')
          case 'username':
            return this.$t('username')
          case 'surname':
            return this.$t('surname')
          case 'min_balance':
            return this.$t('balance_from')
          case 'max_balance':
            return this.$t('balance_to')
          case 'id':
            return 'id'
          case 'online':
            return this.$t('online')
          case 'min_reg_date':
            return this.$t('min_reg_date')
          case 'max_reg_date':
            return this.$t('max_reg_date')
          case 'name':
            return this.$t('name')
          case 'email':
            return this.$t('email')
          case 'tel':
            return this.$t('tel_number')
          case 'lvl_name':
            return this.$t('player_status')
          case 'affiliate_id':
            return this.$t('affiliate')
          case 'pending_verification':
            return this.$t('pending_verification')
          case 'blocked_withdraw':
            return this.$t('withdraw_blocked')
          case 'temp_blocked':
            return this.$t('temp_blocked')
          case 'has_multiaccs':
            return this.$t('multi_accounts')
          case 'has_deposit':
            return this.$t('with_deposits')
          case 'is_online':
            return this.$t('online')
          case 'min_balance':
            return this.$t('withdrawals_from')
          case 'max_balance':
            return this.$t('withdrawals_to')
          case 'min_withdrawal':
            return this.$t('min_withdrawal')
          case 'max_withdrawal':
            return this.$t('min_withdrawal')
          case 'country_code':
            return this.$t('country_code')
        }
  
        return key;
      },
      getFilterValueText(key, value) {
        // chip values
        if (key === 'is_active' && value === true) {
          return this.$t('active')
        }
        if (key === 'is_active' && value === false) {
          return this.$t('forever_blocked')
        }
        switch (value) {
          case true:
            return this.$t('yes')
          case false:
            return this.$t('no')
        }
  
        return value;
      },
  
      getAccountStatus(block, blockEndDate) {
        const currentDate = new Date();
        const endDate = new Date(blockEndDate);
  
        if (block === 2) {
          return this.$i18n.t('forever_blocked')
        } else if (endDate > currentDate) {
          return this.$i18n.t('temp_blocked')
        } else if (block === 0) {
          return this.$i18n.t('active')
        } else {
          return 'NONE';
        }
      },
      getAccountStatusClass(block, blockEndDate) {
      const currentDate = new Date();
      const endDate = new Date(blockEndDate);
      return block === 2
        ? "_red"
        : endDate > currentDate
        ? "_yellow"
        : block === 0
        ? "_green"
        : "_yellow";
    },
      playerBlockStatus(item) {
        switch (item.block) {
          case 0:
            return this.$i18n.t('active');
          case 1:
            return this.$i18n.t('temporary_blocked') + `${new Date(item.blockEnd).toLocaleString()}`;
          case 2:
            return this.$i18n.t('forever_blocked');
        }
        return '-';
      },
      statusColor(block) {
        switch (block) {
          case 0:
            return 'var(--green-success)';
          case 1:
            return '#ff9800';
          case 2:
            return 'var(--red)';
          default:
            return '';
        }
      },
      async setAndSubmitFilters() {
    this.loadingFlag = true;
    this.filters.agent_id = this.agentID;
    const uniqueUserIds = [];
    this.filters.id.forEach(item => {
      if (!uniqueUserIds.includes(item)) {
        uniqueUserIds.push(item);
      }
    });
  
    const userIdsQueryString = uniqueUserIds.map(id => `id=${id}`).join('&');
  
    const otherFilters = Object.entries(this.filters)
      .filter(([key, value]) => key !== 'id' && value !== '')
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  
    const queryString = [userIdsQueryString, otherFilters].filter(Boolean).join('&');
    try {
      await this.$store.dispatch('playersFilters', queryString);
        // Обновляем activeFilters
        for (const [key, value] of Object.entries(this.filters)) {
          if (value !== '') {
            this.$set(this.activeFilters, key, value);
          } else {
            this.$delete(this.activeFilters, key);
          }
        }
    } catch (error) {
      this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
    } finally {
      this.$store.commit('paggination/SET_FILTERS', this.filters);
      this.loadingFlag = false;
      this.$store.commit('paggination/SET_LOADING_FLAG', false);
    }
  },
      async applyFilters() {
          this.filters.page = this.page = this.startPage;
          this.filters.size = this.filterPages;
          this.filters.agent_id = this.agentID;
          this.getFormattedDate();
          this.$store.commit('paggination/SET_FILTERS', this.filters);
          this.setAndSubmitFilters();
      },
      getFormattedDate(){
        if (this.datesToFilter.length > 0) {
            const formattedDates = this.datesToFilter.map(dateStr => {
              const date = new Date(dateStr);
              return date.toISOString().split('T')[0];
            });
            const formattedDateOne = formattedDates[0];
            const formattedDateTwo = formattedDates[1];
            if (this.datesToFilter.length === 2) {
              // both data
              this.filters.min_reg_date = formattedDateOne;
              this.filters.max_reg_date = formattedDateTwo;
            } else if (this.datesToFilter.length === 1) {
              // only one
              this.filters.min_reg_date = formattedDateOne;
            }
          }
      },
      async clearFilters() {
        this.loadingFlag = true;
        Object.keys(this.filters).forEach((key, index) => {
          // this.filters[key] = ''
          this.$delete(this.filters, key);
        })
        Object.keys(this.activeFilters).forEach((key, index) => {
          this.$delete(this.activeFilters, key);
        })
        this.datesToFilter = [],
        // this.filters.is_online = false;
        // this.filters.pending_verification = false;
        // this.filters.has_multiaccs = false;
        // this.filters.has_deposit = false;
        this.filters.id = [];
        this.filters.size = this.filterPages;
        this.filters.page = this.page = this.startPage;
        this.selectedOption = null;
        this.setAndSubmitFilters();
      },
      async removeFilter(key, value) {
        this.loadingFlag = true;
        // Delete chip
        if (key.includes('ID_')) {
          const index = this.activeFilters.id.indexOf(value);
    if (index > -1) {
      this.$delete(this.activeFilters.id, index); // 
    }
        } else{
          this.$delete(this.activeFilters, key);
        }
        // empty string
        if (typeof this.filters[key] === 'boolean') {
          this.$set(this.filters, key, false);
        } else {
          this.$set(this.filters, key, '');
        }
        // is_active
        if (key === 'is_active') {
          this.filters.is_active = '';
          this.selectedOption = null;
        }
        if (key === 'blocked_withdraw') {
          this.filters.blocked_withdraw = '';
          this.selectedOption = null;
        }
        if (key === 'temp_blocked') {
          this.filters.temp_blocked = '';
          this.selectedOption = null;
        }
        if (key === 'min_reg_date') {
          this.datesToFilter[0] = '';
        }
        if (key === 'max_reg_date') {
          this.datesToFilter[1] = '';
        }
        this.setAndSubmitFilters();
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  </style>
  